import FilterChip from 'components/Luxkit/Chips/FilterChip'
import { rem } from 'polished'
import React, { useCallback, useMemo, useState } from 'react'
import styled from 'styled-components'
import LayoutContainer from 'components/Common/LayoutContainer/LayoutContainer'
import HorizontalContainerWithFadedOverflow from 'components/Common/HorizontalContainerWithFadedOverflow'
import Group from 'components/utils/Group'
import VerticalSpacer from 'components/Common/Spacing/VerticalSpacer'
import { getHighIntentHotelOffers } from 'selectors/recomendations/highIntentSelectors'
import { connect } from 'react-redux'
import HighIntentOffersCarousel from 'components/OfferList/HighIntent/HighIntentOffersCarousel'
import RecentSearchesCarousel from 'home/components/RecentSearches/RecentSearchesCarousel'
import Heading from 'components/Luxkit/Typography/Heading'
import useRecentSearches from 'hooks/Search/useRecentSearches'
import { useAppDispatch } from 'hooks/reduxHooks'
import { removeRecentSearch } from 'actions/SearchActions'

const SectionContainer = styled(VerticalSpacer)`
  padding-top: ${rem(32)};
  padding-bottom: ${rem(24)};
`

type RecentSearchesTab =
  | 'recentSearches'
  | 'previouslyViewedOffers'

interface RecentSearchesTabOptions {
  id: RecentSearchesTab;
  title: string;
}

interface Props {
  previouslyViewedOffers: Array<App.HighIntentOffer>
  titleVariant?: React.ComponentProps<typeof Heading>['variant'];
}

function RecentSearches(props: Props) {
  const { previouslyViewedOffers, titleVariant = 'heading6' } = props

  const [
    selectedTab,
    setSelectedTab,
  ] = useState<RecentSearchesTab | undefined>()
  const recentSearches = useRecentSearches()
  const dispatch = useAppDispatch()
  const onCloseRecentSearch = useCallback((recentSearch: App.FullRecentSearch) => {
    dispatch(removeRecentSearch(recentSearch.recentSearchId))
  }, [dispatch])

  function onTabClick(option: RecentSearchesTabOptions) {
    setSelectedTab(option.id)
  }

  const RecentSearchesTabOptions: Array<RecentSearchesTabOptions> = useMemo(() => {
    const options: Array<RecentSearchesTabOptions> = []

    if (recentSearches.length) {
      options.push({ id: 'recentSearches', title: 'Recent Searches' })
    }
    if (previouslyViewedOffers.length) {
      options.push({ id: 'previouslyViewedOffers', title: 'Previously Viewed Offers' })
    }

    const hasRecentSearches = recentSearches.length > 0
    const hasPreviouslyViewedOffers = previouslyViewedOffers.length > 0

    if (!selectedTab || (hasRecentSearches && !hasPreviouslyViewedOffers)) {
      setSelectedTab('recentSearches')
    } else if (!selectedTab && hasPreviouslyViewedOffers || (!hasRecentSearches && hasPreviouslyViewedOffers)) {
      setSelectedTab('previouslyViewedOffers')
    }

    return options
  }, [recentSearches.length, previouslyViewedOffers.length, selectedTab])

  if (!RecentSearchesTabOptions.length) {
    return null
  }

  return (
    <SectionContainer gap={16}>
      <LayoutContainer>
        <VerticalSpacer gap={16}>
          <Heading variant={titleVariant}>
            Continue searching for your next escape
          </Heading>
          <HorizontalContainerWithFadedOverflow>
            <Group direction="horizontal" gap={8}>
              {RecentSearchesTabOptions.map((option) => <FilterChip
                selected={option.id === selectedTab}
                onClick={() => onTabClick(option)}
                key={option.id}
                size="medium"
              >
                {option.title}
              </FilterChip>)}
            </Group>
          </HorizontalContainerWithFadedOverflow>
        </VerticalSpacer>
      </LayoutContainer>
      <LayoutContainer>
        {selectedTab === 'recentSearches' && <RecentSearchesCarousel
          recentSearches={recentSearches}
          onClose={onCloseRecentSearch}
        />}
        {selectedTab === 'previouslyViewedOffers' && <HighIntentOffersCarousel
          highIntentOffers={previouslyViewedOffers}
          analyticsLabel="hotels"
        />}
      </ LayoutContainer>
    </SectionContainer>
  )
}

function mapStateToProps(state: App.State) {
  return {
    previouslyViewedOffers: getHighIntentHotelOffers(state),
  }
}

export default connect(mapStateToProps)(RecentSearches)
