import { useEffect, useMemo } from 'react'

import { fetchHighIntentOffers, fetchPeopleLikeMeRecommendations, fetchPersonalisedAlternativeOffers, fetchPersonalisedPreferenceOffers } from 'actions/RecommendationActions'
import { useAppDispatch, useAppSelector } from 'hooks/reduxHooks'
import { selectLoggedIn } from 'selectors/accountSelectors'
import { get as getLocalStorage } from 'lib/storage/expirableLocalStorage'
import { unique, without } from 'lib/array/arrayUtils'

/**
 * Constructs a list of personalized offers for the user in this priority order:
 * 1. Personalized alternatives if available
 * 2. "People like me" recommendations if user is logged in
 * 3. Travel preference based offers - shown first if preferences were updated in last 24h
 *
 * High intent offers are excluded since they are displayed separately.
 */
export default function useTPFMOfferList(): App.OfferList {
  const dispatch = useAppDispatch()
  const travelPreferencesUpdatedInLast24Hours = useMemo(() => getLocalStorage<number>('travelPreferencesLastUpdatedAt'), [])
  const isLoggedIn = useAppSelector(state => selectLoggedIn(state))
  const peopleLikeMe = useAppSelector(state => state.recommendations.personalisedPeopleLikeMe)
  const personalisedPreferences = useAppSelector(state => state.recommendations.personalisedPreferences)
  const personalisedAlternatives = useAppSelector(state => state.recommendations.personalisedAlternatives)
  const highIntent = useAppSelector(state => state.recommendations.highIntent)

  useEffect(() => {
    if (isLoggedIn) {
      dispatch(fetchPeopleLikeMeRecommendations())
      dispatch(fetchPersonalisedPreferenceOffers())
    }

    dispatch(fetchPersonalisedAlternativeOffers())
    dispatch(fetchHighIntentOffers())
  }, [dispatch, isLoggedIn])

  const offerList = useMemo<App.OfferList>(() => {
    let candidates: Array<string> = []

    if (personalisedAlternatives.state === 'done' && personalisedAlternatives.offers.length > 0) {
      candidates = personalisedAlternatives.offers.map(offer => offer.offerId)
    }
    else if (isLoggedIn && peopleLikeMe.state === 'done' && peopleLikeMe.offers.length > 0) {
      candidates = peopleLikeMe.offers.map(offer => offer.offerId)
    }

    const preferenceOffers = personalisedPreferences.offers.map(offer => offer.offerId)
    candidates = travelPreferencesUpdatedInLast24Hours ? [...preferenceOffers, ...candidates] : [...candidates, ...preferenceOffers]
    candidates = unique(candidates)

    const fetching = [personalisedAlternatives.state, highIntent.state].includes('loading') || (isLoggedIn && [peopleLikeMe.state, personalisedPreferences.state].includes('loading'))
    const error = personalisedAlternatives.error || personalisedPreferences.error || peopleLikeMe.error || personalisedPreferences.error
    // Disclude high intent offers as they are shown above the TPFM carousel on the homepage
    const offerIds = without(candidates, ...highIntent.offers.map(offer => offer.offerId))

    return {
      fetching,
      error,
      offerIds,
      key: 'topPicks',
    }
  }, [personalisedAlternatives, isLoggedIn, peopleLikeMe, highIntent, personalisedPreferences, travelPreferencesUpdatedInLast24Hours])

  return offerList
}
