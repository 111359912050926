import React, { useMemo } from 'react'
import { connect } from 'react-redux'

import Heading from 'components/Luxkit/Typography/Heading'
import { rem } from 'polished'
import styled from 'styled-components'
import VerticalSpacer from 'components/Common/Spacing/VerticalSpacer'
import LayoutContainer from 'components/Common/LayoutContainer'
import OfferListCarousel from 'components/OfferList/OfferListCarousel'
import TextLink from 'components/Luxkit/TextLink'
import { selectLoggedIn } from 'selectors/accountSelectors'
import { OfferListEventsDispatchAction, OfferListEventsProvider } from 'components/OfferList/OfferListEventsContext'
import HotelOfferCarousel from 'home/components/OfferCarousels/HotelOfferCarousel'
import OfferCarouselLookingForSomethingElse from '../LookingForSomethingElse/OfferCarouselLookingForSomethingElse'
import { ANYWHERE_SEARCH_ITEM } from 'constants/search'
import config from 'constants/config'
import { encodeSearchParams } from 'lib/search/searchUtils'
import useTPFMOfferList from 'hooks/Recommendation/useTPFMOfferList'
import Group from 'components/utils/Group'
import Pane from 'components/Common/Pane'
import TextButton from 'components/Luxkit/Button/TextButton'

const CarouselContainer = styled(Pane)`
  padding-top: ${rem(40)};
  padding-bottom: ${rem(40)};
`

interface Props {
  userGivenName?: string;
  viewAllUrl?: string;
  isLoggedIn: boolean;
  onHotelListEvent: (dispatchAction: OfferListEventsDispatchAction) => void;
  titleVariant?: React.ComponentProps<typeof Heading>['variant'];
  overrideTitle?: React.ReactNode;
  overrideOfferList?: App.OfferList;
  hideEditPreferences?: boolean;
}

/**
 * Displays personalized offers if 9+ are available, otherwise shows hotel carousel
 */
function TopPicksForMeCarousel({
  userGivenName,
  viewAllUrl,
  isLoggedIn,
  onHotelListEvent,
  titleVariant = 'heading2',
  overrideTitle,
  overrideOfferList,
  hideEditPreferences = false,
}: Props) {
  const tpfmOfferList = useTPFMOfferList()
  const title = overrideTitle ?? <>Top picks for <i>{userGivenName || 'you'}</i></>

  const searchUrl = useMemo(() => {
    const query = encodeSearchParams({
      searchItem: ANYWHERE_SEARCH_ITEM,
      rooms: [config.search.defaultOccupants],
    })
    return `/search?${query.toString()}`
  }, [])

  if (!tpfmOfferList.fetching && tpfmOfferList.offerIds.length < 9) {
    return (
      <OfferListEventsProvider onListEvent={onHotelListEvent}>
        <HotelOfferCarousel viewAllUrl={viewAllUrl}/>
      </OfferListEventsProvider>
    )
  }

  return (
    <CarouselContainer>
      <VerticalSpacer gap={24}>
        <LayoutContainer>
          <VerticalSpacer gap={16}>
            <Group
              direction="horizontal"
              horizontalAlign="space-between"
              verticalAlign="center"
              gap={16}
            >
              <Group direction="horizontal" gap={16} verticalAlign="center">
                <VerticalSpacer gap={2}>
                  <Heading variant={titleVariant}>{title}</Heading>
                  {isLoggedIn && !hideEditPreferences && <TextLink to="/account/travel-preferences" size="medium" weight="regular">
                    Edit your preferences
                  </TextLink>}
                </VerticalSpacer>
              </Group>
              <Group direction="horizontal" gap={16} verticalAlign="center">
                <TextButton
                  kind="tertiary"
                  to={searchUrl}
                >
                  View all
                </TextButton>
              </Group>
            </Group>
          </VerticalSpacer>
        </LayoutContainer>
        <OfferListCarousel
          overrideOfferList={overrideOfferList ?? tpfmOfferList}
          tabletLimit="default"
          tileStyle="card-medium"
        >
          <OfferCarouselLookingForSomethingElse to={searchUrl} />
        </OfferListCarousel>
      </VerticalSpacer>
    </CarouselContainer>
  )
}

function mapState(state: App.State) {
  return {
    userGivenName: state.auth.account.givenName,
    isLoggedIn: selectLoggedIn(state),
  }
}

export default connect(mapState)(TopPicksForMeCarousel)
