import React, { useCallback } from 'react'
import CardCarousel from 'components/Luxkit/Carousel/CardCarousel'
import RecentSearchTile from './RecentSearchTile'
import { encodeSearchParams } from 'lib/search/searchUtils'
import { DEFAULT_FLEXIBLE_DURATION_RANGE } from 'constants/search'
import { pushWithRegion } from 'actions/NavigationActions'
import { useAppDispatch } from 'hooks/reduxHooks'

interface Props {
  recentSearches: Array<App.FullRecentSearch>;
  onClose: (recentSearch: App.FullRecentSearch) => void;
}

function RecentSearchesCarousel(props: Props) {
  const { recentSearches, onClose } = props
  const dispatch = useAppDispatch()

  const onClick = useCallback((recentSearch: App.FullRecentSearch) => {
    const { dates, rooms } = recentSearch
    const newSearch = encodeSearchParams({
      flexibleMonths: dates?.flexibleMonths,
      flexibleNights: dates?.flexibleDuration as DEFAULT_FLEXIBLE_DURATION_RANGE,
      dates: {
        checkIn: dates.checkIn,
        checkOut: dates.checkOut,
      },
      urlSearch: '',
      rooms,
      searchItem: recentSearch.searchItem,
      userSelectedFlexibleMonths: !!dates?.flexibleMonths,
    }).toString()

    dispatch(pushWithRegion('/search', newSearch))
  }, [dispatch])

  return <CardCarousel>
    {recentSearches.map((recentSearch, index) => (
      <RecentSearchTile
        recentSearch={recentSearch}
        key={index}
        onClose={onClose}
        onClick={onClick}
      />))}
  </CardCarousel>
}

export default RecentSearchesCarousel
